import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import * as userActions from "@/actions/userActions";
import Button from "@/components/Button";
import EmailInput from "@/components/GenericFormFieldInputs/EmailInput";
import TextInput from "@/components/GenericFormFieldInputs/TextInput";
import Section from "@/components/Section";
import { CREATE_TOKEN } from "@/mutations/userMutations";
import { empty } from "@/utils/noopUtils";
import { getServerSettings } from "@/utils/serverUtils";
import AuthenticationPage from "./AuthenticationPage";

const useStyles = makeStyles(() => ({
  childrenClassName: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();

  const { control, errors, handleSubmit } = useForm({
    defaultValues: { email: "", password: "" },
  });

  const [
    createToken,
    { loading: createTokenLoading, error: createTokenError },
  ] = useMutation(CREATE_TOKEN, {
    onError: empty,
    onCompleted: ({ createToken }) => {
      if (!createToken?.accessToken) return;
      const { accessToken } = createToken;

      localStorage.setItem("token", accessToken);
      dispatch(
        userActions.getAccessTokenSuccess({
          accessToken: accessToken,
        }),
      );
    },
  });

  const handleLogin = ({ email, password }) => {
    if (createTokenLoading) return;

    const { clientId, clientSecret } = getServerSettings();

    createToken({
      variables: {
        input: {
          clientId,
          clientSecret,
          grantType: "password",
          username: email,
          password,
        },
      },
    });
  };

  const handleResetPasswordLink = () => {
    router.push("/recoverPassword", "/recoverPassword");
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleSubmit(handleLogin)();
    }
  };

  const isAlertShown =
    !!errors.email || !!errors.password || !!createTokenError;

  return (
    <AuthenticationPage
      headerText="Login to connect with your customer"
      onKeyUp={handleKeyUp}
    >
      <form>
        <Section
          isAlertShown={isAlertShown}
          alertText={"Invalid email or password, please try again"}
          childrenClassName={classes.childrenClassName}
          style={{ marginBottom: "16px" }}
        >
          <EmailInput
            required
            control={control}
            controlName="email"
            error={!!errors.email || !!createTokenError}
            InputTextFieldProps={{
              autoFocus: true,
              label: "Your email",
              autoComplete: "username",
            }}
          />

          <TextInput
            required
            control={control}
            controlName="password"
            error={!!errors.password || !!createTokenError}
            InputTextFieldProps={{
              type: "password",
              label: "Your password",
              autoComplete: "current-password",
            }}
          />
        </Section>

        <Button
          fullWidth
          isLoading={createTokenLoading}
          style={{ fontSize: "1rem" }}
          onClick={handleSubmit(handleLogin)}
        >
          Login
        </Button>

        <Button
          disableRipple
          isLink
          style={{ fontSize: "1rem" }}
          onClick={handleResetPasswordLink}
        >
          Recover lost password
        </Button>
      </form>
    </AuthenticationPage>
  );
};

export default LoginPage;
