import { Controller } from "react-hook-form";

import InputTextField from "@/components/InputTextField";
import {
  validateEmail,
  validateNonEmptyString,
} from "@/utils/formValidationUtils";

const EmailInput = ({
  control,
  controlName,
  displayName,
  required,
  error,
  ControllerProps = {},
  InputTextFieldProps = {},
  customValidateFunctions = {},
}) => {
  const { rules = {}, ...otherControllerProps } = ControllerProps;
  const { inputProps, ...otherInputTextFieldProps } = InputTextFieldProps;

  const validateRequired = (value) => {
    const message = displayName
      ? `${displayName} is required`
      : "This field is required";

    if (required) return validateNonEmptyString(value, message);

    return true;
  };

  const validateEmailInput = (value) => {
    if (!value) return true;
    return validateEmail(value, "Invalid email input");
  };

  const validateUppercase = (value) => {
    if (!value) return true;

    const hasNoUppercase = /^[^A-Z]*$/.test(String(value));
    if (hasNoUppercase) return true;

    return "Must not contain uppercase letters";
  };

  const handleEmailValueChange = ({ event, onChange }) => {
    const parsedValue = event.target.value.toLowerCase();
    onChange(parsedValue);
  };

  return (
    <Controller
      control={control}
      name={controlName}
      render={({ value, onChange, ref }) => {
        return (
          <InputTextField
            value={value}
            error={Boolean(error)}
            inputProps={{ ref, ...inputProps }}
            onChange={(event) => handleEmailValueChange({ event, onChange })}
            {...otherInputTextFieldProps}
          />
        );
      }}
      rules={{
        validate: {
          validateRequired,
          validateEmailInput,
          validateUppercase,
          ...customValidateFunctions,
        },
        ...rules,
      }}
      {...otherControllerProps}
    />
  );
};

export default EmailInput;
