import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import ErrorText from "@/components/ErrorText";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: "1.23rem",
    fontWeight: "bold",
    marginBottom: "4px",
    color: theme.palette.textPrimary.main,
  },

  sectionLabel: {
    fontSize: "1rem",
    fontWeight: "500",
    marginBottom: "5px",
    color: theme.palette.textPrimary.main,
  },

  sectionDescription: {
    fontSize: "1rem",
    fontWeight: "normal",
    marginBottom: "5px",
    color: theme.palette.grey[1500],
  },

  bottomDescription: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "5px",
    lineHeight: "1.3rem",
    fontSize: "1rem",
  },

  isHidden: {
    display: "none",
  },

  sectionErrorText: {
    display: "inline-block",
    margin: "5px 0px 0px",
  },
}));

const Section = ({
  children,
  title,
  label,
  description,
  descriptionPosition = "top",
  required,
  isAlertShown,
  alertText = "This field is required",
  childrenClassName,
  sectionClassName,
  sectionTitleClassName,
  sectionLabelClassName,
  sectionDescriptionClassName,
  isHidden = false,
  ErrorTextProps = {},
  ...otherProps
}) => {
  const classes = useStyles();

  const shouldTransformTitle = typeof title === "string" && required;
  const shouldTransformLabel = typeof label === "string" && required;

  return (
    <div
      className={clsx(sectionClassName, { [classes.isHidden]: isHidden })}
      {...otherProps}
    >
      {title && (
        <div className={clsx(classes.sectionTitle, sectionTitleClassName)}>
          {shouldTransformTitle ? `${title} *` : title}
        </div>
      )}

      {label && (
        <div className={clsx(classes.sectionLabel, sectionLabelClassName)}>
          {shouldTransformLabel ? `${label} *` : label}
        </div>
      )}

      {description && descriptionPosition === "top" && (
        <div
          className={clsx(
            classes.sectionDescription,
            sectionDescriptionClassName,
          )}
        >
          {description}
        </div>
      )}

      {children && <div className={childrenClassName}>{children}</div>}

      {description && descriptionPosition === "bottom" && (
        <div
          className={clsx(
            classes.sectionDescription,
            classes.bottomDescription,
            sectionDescriptionClassName,
          )}
        >
          {description}
        </div>
      )}

      <ErrorText
        isShown={isAlertShown}
        alertText={alertText}
        className={classes.sectionErrorText}
        {...ErrorTextProps}
      />
    </div>
  );
};

export default Section;
