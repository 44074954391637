import React from "react";
import { Controller } from "react-hook-form";

import InputTextField from "@/components/InputTextField";
import { validateNonEmptyString } from "@/utils/formValidationUtils";

const TextInput = ({
  control,
  controlName,
  displayName,
  required,
  error,
  customValidateFunctions = {},
  ControllerProps = {},
  InputTextFieldProps = {},
}) => {
  const { rules = {}, ...otherControllerProps } = ControllerProps;
  const { inputProps, ...otherInputTextFieldProps } = InputTextFieldProps;

  const validateRequired = (value) => {
    const errorMessage = displayName
      ? `${displayName} is required`
      : "This field is required";

    if (required) return validateNonEmptyString(value, errorMessage);
    return true;
  };

  return (
    <Controller
      control={control}
      name={controlName}
      render={({ value, onChange, ref }) => {
        return (
          <InputTextField
            value={value}
            error={Boolean(error)}
            onChange={(event) => onChange(event.target.value)}
            inputProps={{ ref, ...inputProps }}
            {...otherInputTextFieldProps}
          />
        );
      }}
      rules={{
        validate: { validateRequired, ...customValidateFunctions },
        ...rules,
      }}
      {...otherControllerProps}
    />
  );
};

export default TextInput;
