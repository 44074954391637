import { makeStyles } from "@material-ui/core";

import LogoTalkhub from "@/assets/images/logo-talkhub.svg";
import * as commonUtils from "@/utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100dvh",
    minHeight: "min-content",
    minWidth: "min-content",
  },

  content: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },

  logo: {
    width: "220px",
    padding: "30px 0px 16px 0px",
  },

  headerText: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: "700",
    textAlign: "center",
  },

  childrenContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.16)",
    alignSelf: "center",
    justifySelf: "center",
    borderRadius: "10px",
    marginTop: "25px",
    padding: "20px 25px 25px",
    width: "356px",
  },

  footer: {
    display: "flex",
    flexFlow: "row nowrap",
    padding: "30px 0px",
    fontSize: "1rem",
    "& p": {
      whiteSpace: "nowrap",
      color: theme.palette.grey[1755],
    },
    "& a": {
      marginLeft: "10px",
      color: theme.palette.link.main,
    },
  },
}));

/*
  Reference:
  https://www.chromium.org/developers/design-documents/create-amazing-password-forms
*/
const AuthenticationPage = ({ children, headerText, ...otherProps }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} {...otherProps}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <LogoTalkhub />
        </div>

        <p className={classes.headerText}>{headerText}</p>

        <div className={classes.childrenContainer}>{children}</div>
      </div>

      <div className={classes.footer}>
        <p>Messenger &copy; {commonUtils.getCurrentYear()}</p>

        <a
          href="https://www.talkhub.io/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms
        </a>

        <a
          href="https://www.talkhub.io/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy
        </a>
      </div>
    </div>
  );
};

export default AuthenticationPage;
