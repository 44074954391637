import { useRouter } from "next/router";

import AppLayout from "@/components/Layouts/App/AppLayout";
import { default as Login } from "@/components/LoginPage";
import PageLoading from "@/components/PageLoading";
import { useAuthRouteProtection } from "@/utils/hookUtils";

const LoginPage = () => {
  const router = useRouter();

  const handleLoggedInCallback = () => {
    return router.replace("/inbox");
  };

  const { isLoading, isAuthenticated } = useAuthRouteProtection({
    isRouteProtected: false,
    onLoggedInCallback: handleLoggedInCallback,
  });

  if (isLoading || isAuthenticated) return <PageLoading />;
  return <Login />;
};

LoginPage.getLayout = (page) => <AppLayout> {page}</AppLayout>;

export default LoginPage;
