import { ErrorMessage } from "@hookform/error-message";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  alertText: {
    color: theme.palette.alert.main,
    fontSize: "1rem",
    width: "100%",
    margin: "0px auto 20px",
  },
}));

const Error = ({ message, className, ...otherProps }) => {
  const classes = useStyles();

  return (
    <span className={clsx(classes.alertText, className)} {...otherProps}>
      {message}
    </span>
  );
};

const ErrorText = ({
  isShown,
  alertText,
  className,
  ErrorMessageProps = {},
  ...otherProps
}) => {
  if (!isShown) return null;

  if (ErrorMessageProps?.name && ErrorMessageProps?.errors) {
    return (
      <ErrorMessage
        name={ErrorMessageProps.name}
        errors={ErrorMessageProps.errors}
        render={({ message }) => (
          <Error message={message} className={className} {...otherProps} />
        )}
      />
    );
  }

  return <Error message={alertText} className={className} {...otherProps} />;
};

export default ErrorText;
