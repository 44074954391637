import { Typography, withStyles } from "@material-ui/core";
import React from "react";

const HeaderTypography = ({
  children,
  fontSize = "1rem",
  fontWeight = "bold",
  color = null,
  margin = null,
  lineHeight = null,
  ...otherProps
}) => {
  const HeaderTypography = withStyles((theme) => ({
    root: {
      fontSize,
      fontWeight,
      color: color || theme.palette.textPrimary.main,
      margin,
      ...(lineHeight && { lineHeight }),
    },
  }))(Typography);

  return (
    <HeaderTypography {...otherProps} variant="h6">
      {children}
    </HeaderTypography>
  );
};

export default React.memo(HeaderTypography);
