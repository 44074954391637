import { Grid, TextField, withStyles } from "@material-ui/core";
import clsx from "clsx";
import { has, isEmpty, omit } from "lodash";
import React, { useMemo } from "react";

import ErrorText from "@/components/ErrorText";
import HeaderTypography from "@/components/HeaderTypography";

const styles = (theme) => ({
  root: ({ backgroundColor = "white", height = "32px", border, disabled }) => ({
    "& .MuiInputBase-input": {
      color: theme.palette.grey[3000],

      "&::placeholder": {
        fontStyle: "italic",
        fontSize: "inherit",
        color: theme.palette.grey[1725],
        opacity: 1.0,
      },
    },
    "& .MuiInputLabel-outlined": {
      "&.MuiInputLabel-shrink": {
        fontStyle: "normal",
        fontWeight: "600",
      },
    },
    "& label": {
      fontStyle: "italic",
      color: theme.palette.grey[1500],
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: disabled
        ? theme.palette.disabledInput.main
        : backgroundColor,
      minHeight: height,
      height: height,
      "& fieldset": {
        border: `1px solid ${theme.palette.primaryBorder.main}`,
      },
      "&:hover fieldset": {
        border: `1px solid ${theme.palette.primaryBorder.main}`,
      },
      "&.Mui-focused fieldset": {
        border: border || `1px solid ${theme.palette.primary.main}`,
      },
      "&.MuiOutlinedInput-multiline": {
        padding: "unset",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 10px",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0",
    },
    "& .customHelperText": {
      fontSize: "1rem",
      color: theme.palette.grey[1500],
      letterSpacing: "0px",
      fontWeight: "500",
    },
  }),
});

const InputTextField = ({
  textFieldRef,
  GridProps,
  HeaderProps,
  classes,
  children,
  className,
  required,
  headerText,
  helperText = "" /* Description at the bottom */,
  inputName = "",
  multiline = false,
  maxRows = 0,
  margin = "auto auto",
  errors,
  onChange,
  color = null,
  inputProps,
  ...otherProps
}) => {
  const title = required ? `${headerText} *` : headerText;
  const isErrorInputFieldColor =
    otherProps?.error || has(errors, inputName) || false;

  const newInputProps = useMemo(() => {
    /* Ensures scrollbar on multiline inputs appear on the right border */
    if (multiline) {
      return {
        ...inputProps,
        style: {
          height: "100%",
          padding: "10.5px 10px",
          overflow: "scroll",
          overflowX: "hidden",
          boxSizing: "border-box",
          ...(inputProps?.style && inputProps.style),
        },
      };
    }

    return inputProps;
  }, [multiline, inputProps]);

  return (
    <Grid item container direction="column" {...GridProps}>
      {headerText && (
        <HeaderTypography fontWeight="500" {...HeaderProps}>
          {title}
        </HeaderTypography>
      )}
      <TextField
        ref={textFieldRef}
        className={clsx(classes.root, className)}
        multiline={multiline}
        maxRows={maxRows}
        fullWidth
        variant="outlined"
        margin="dense"
        style={{ margin, color }}
        inputProps={newInputProps}
        helperText={
          !isEmpty(errors) && has(errors, inputName) ? (
            <ErrorText
              isShown={true}
              ErrorMessageProps={{
                errors,
                name: inputName,
              }}
              style={{ margin: "unset" }}
            />
          ) : (
            !!helperText && (
              <span className="customHelperText">{helperText}</span>
            )
          )
        }
        onChange={onChange}
        error={isErrorInputFieldColor}
        {...otherProps}
      >
        {children}
      </TextField>
    </Grid>
  );
};

const ForwardRefInputTextField = React.forwardRef((props, ref) => {
  /* Separate style-specific and DOM props to avoid unrecognized prop console error */
  const textFieldProps = omit(props, ["backgroundColor", "height", "border"]);

  return <InputTextField {...textFieldProps} textFieldRef={ref} />;
});

export default withStyles(styles)(ForwardRefInputTextField);
